<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="total"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ total }} New
        </b-badge>
      </div>
    </li>
    <!-- {{ notifications }} -->
    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-once
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
      v-if="notifications.length > 0"
    >
      <!-- Account Notification -->
      <b-link
        v-for="notification in notifications"
        :key="notification.title"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :src="notification.avatar"
              :text="notification.avatar"
              :variant="notification.avatar"
            />
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link>

      <!-- System Notification Toggler -->
      <div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">
          Church Notifications
        </h6>
        <b-form-checkbox
          :checked="true"
          switch
        />
      </div>

      <!-- System Notifications -->
      <b-link
        v-for="notification in churchNotifications"
        :key="notification.title"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :variant="notification.avatar"
            >
              <feather-icon icon="HomeIcon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
    >Read all notifications</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import axios from '@/libs/axios'
import { getUserData } from '@/auth/utils'
import { ref, computed } from '@vue/composition-api'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  setup() {
    /* eslint-disable global-require */
    // const notifications = [
    //   {
    //     title: 'Congratulation Sam 🎉',
    //     avatar: require('@/assets/images/avatars/6-small.png'),
    //     subtitle: 'Won the monthly best seller badge',
    //     type: 'light-success',
    //   },
    //   {
    //     title: 'New message received',
    //     avatar: require('@/assets/images/avatars/9-small.png'),
    //     subtitle: 'You have 10 unread messages',
    //     type: 'light-info',
    //   },
    //   {
    //     title: 'Revised Order 👋',
    //     avatar: 'MD',
    //     subtitle: 'MD Inc. order updated',
    //     type: 'light-danger',
    //   },
    // ]
    // /* eslint-disable global-require */

    // const churchNotifications = [
    //   {
    //     title: 'Server down',
    //     subtitle: 'USA Server is down due to hight CPU usage',
    //     type: 'light-danger',
    //     icon: 'XIcon',
    //   },
    //   {
    //     title: 'Sales report generated',
    //     subtitle: 'Last month sales report generated',
    //     type: 'light-success',
    //     icon: 'CheckIcon',
    //   },
    //   {
    //     title: 'High memory usage',
    //     subtitle: 'BLR Server using high memory',
    //     type: 'light-warning',
    //     icon: 'AlertTriangleIcon',
    //   },
    // ]
    const total = ref(0)
    const notifications = ref([])
    const churchNotifications = ref([])
    
    const userData = getUserData()

    const getNotifs = setInterval(()=>{

      axios.get(`/alert/${userData.fullName}`).then(res => {
      
          //
          notifications.value = res.data.filter(a=>a.type == 'user')
          churchNotifications.value = res.data.filter(a=>a.type == 'church')

          total.value = res.data.length
        
      })

    }, 30000)

    //
    getNotifs

    axios.get(`/alert/${userData.fullName}`).then(res => {
      
      //
      notifications.value = res.data.filter(a=>a.type == 'user')
      churchNotifications.value = res.data.filter(a=>a.type == 'church')

      total.value = res.data.length
  
  })

    // const getNotifications = computed(() => {
    //   return notifications
    // })

    

    // const getChurchNotifications = computed(() => {
    //   return churchNotifications
    // })
  

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      notifications,
      churchNotifications,
      perfectScrollbarSettings,
      // getNotifications,
      // getChurchNotifications
      total
    }
  },
}
</script>

<style>

</style>
